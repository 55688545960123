/* eslint-disable import/extensions,no-param-reassign */
import { copyDom as copyDom1, copyText as copyText1 } from '@livelybone/copy';
import Big from 'big.js';
import QRCode from 'qrcode';
import { isBiconomy } from './UserAgent';

// 复制 DOM 元素
export function copyDom(el, options) {
  return copyDom1(el, options) ? Promise.resolve('copy.success') : Promise.reject(new Error('copy.failed'));
}

// 安卓webview打开, 有 navigator.clipboard.writeText 方法，但是会出现没有读写权限的报错
export default function copyText2(text) {
  return new Promise((res, rej) => {
    if (navigator.clipboard && navigator.clipboard.writeText && !isBiconomy()) {
      navigator.clipboard
        .writeText(text)
        .then(() => res(true))
        .catch((e) => {
          const err = new Error(e.message ? `copyText failed, reason: ${e.message}` : 'copyText failed');
          rej(err);
        });
    } else {
      const textEl = document.createElement('span');
      textEl.style.position = 'fixed';
      textEl.style.left = '0';
      textEl.style.top = '0';
      textEl.style.zIndex = '-999';
      textEl.innerText = text;

      document.body.appendChild(textEl);
      const bool = copyDom(textEl);
      document.body.removeChild(textEl);

      if (bool) res(bool);
      else rej(new Error('copyText failed'));
    }
  });
}

// 复制文本内容
export function copyText(text) {
  const res = copyText2(text);

  // eslint-disable-next-line no-nested-ternary
  return res ? (res.then ? res.then(() => 'copy.success') : Promise.resolve('copy.success')) : Promise.reject(new Error('copy.failed'));
}

// 将 ms 转换为秒
export function msToS(time) {
  return Math.ceil(time / 1000);
}

// 二维码生成
export function QrCode(url, options) {
  const opts = {
    type: 'image/jpeg',
    rendererOpts: { quality: 0.3 },
    ...options,
  };
  return new Promise((res, rej) => {
    QRCode.toDataURL(url, opts, (err, dataUrl) => {
      if (err) rej(err);
      res(dataUrl);
    });
  });
}

// 转换成非科学计数法表示的数字
export function toNumNotExponential(val) {
  const arr = val.toString().match(/(-?[\d.]*)e(-?\d*)/);
  if (!arr) return val;
  const [, decimal = ''] = (arr[1] || '').split('.');
  const exponential = (-arr[2] || 0) + decimal.length;
  return new Big(val).toFixed(Math.max(0, exponential));
}

// 把对象中的数值型属性都转换成非科学计数法表示的数字
export function dealNumStringForObj(obj) {
  return Object.keys(obj).reduce((pre, k) => {
    pre[k] = +obj[k] ? toNumNotExponential(obj[k]) : obj[k];
    return pre;
  }, {});
}

// 去掉字符串中的空格以及换行
export function strTrimAll(str) {
  return str.split(/[\s\n]/).join('');
}

/**
 * @param { Array } arr
 * @param { Array } vals
 * @return Array
 * @desc 从数组 arr 中去除和 vals 数组元素相同的元素
 * */
export function reduceValOfArr(arr, vals = []) {
  const arr1 = [];
  arr.forEach((val) => {
    if (!vals.includes(val)) {
      arr1.push(val);
    }
  });
  return arr1;
}

// 防抖动函数
/* eslint-disable */
export function debounce(func, wait) {
  let timeout = null;
  return function () {
    const context = this;
    const args = arguments;
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      func.apply(context, args);
    }, wait);
  };
}
// 节流函数
export function throttle(func, wait) {
  let timeout = null;
  return function () {
    const context = this;
    let args = arguments;
    if (!timeout) {
      timeout = setTimeout(() => {
        timeout = null;
        func.apply(context, args);
      }, wait);
    }
  };
}

export function dateGap(time) {
  let days = Math.floor(time / (24 * 3600));
  //计算出小时数
  let leave1 = time % (24 * 3600); //计算天数后剩余的毫秒数
  let hours = Math.floor(leave1 / 3600);
  //计算相差分钟数
  let leave2 = leave1 % 3600; //计算小时数后剩余的毫秒数
  let minutes = Math.floor(leave2 / 60);
  //计算相差秒数
  let seconds = Math.floor(leave2 % 60); //计算分钟数后剩余的毫秒数
  return {
    days,
    hours,
    minutes,
    seconds,
  };
  // return `${days} D ${hours} H ${minutes} M ${seconds} S`;
}

export function formatDate(timestamp) {
  const date = new Date(timestamp*1000);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0'); // getMonth() 返回的月份是从0开始的
  const day = String(date.getDate()).padStart(2, '0');

  return `${year}-${month}-${day}`;
}
export function formatTime(timestamp) {
  if (!isValidTimestamp(timestamp)) {
    return '--:--:--';
  }

  const date = new Date(timestamp*1000);
  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');
  const seconds = String(date.getSeconds()).padStart(2, '0');

  return `${hours}:${minutes}:${seconds}`;
}


export function getTimeRemaining(endTime) {
  if (!isValidTimestamp(endTime)) {
    return '--:--:--';
  }
  const now = new Date();
  const end = new Date(endTime*1000);
  const totalSeconds = Math.floor((end - now) / 1000);

  if (totalSeconds <= 0) {
    return '--:--:--';
  }

  const hours = String(Math.floor(totalSeconds / 3600)).padStart(2, '0');

  // const minutes = String(Math.floor(totalSeconds / 60)).padStart(2, '0');
  const minutes = String(Math.floor((totalSeconds % 3600) / 60)).padStart(2, '0');

  const seconds = String((totalSeconds % 60)).padStart(2, '0');

  return `${hours}:${minutes}:${seconds}`;
}

// 判断是否是正确时间戳
export function isValidTimestamp(timestamp) {
  // 将输入转换为数字
  const numTimestamp = Number(timestamp);

  // 检查是否为有效数字且不为 0
  if (isNaN(numTimestamp) || numTimestamp === 0) {
    return false;
  }

  // 检查是否为整数
  if (!Number.isInteger(numTimestamp)) {
    return false;
  }

  // 检查时间戳是否在有效范围内
  // 使用一个合理的范围：从 2000 年到 2100 年
  const minTimestamp = 946656000; // 1970-01-01 00:00:00 UTC
  const maxTimestamp = 4102444800; // 2100-01-01 00:00:00 UTC

  return numTimestamp > minTimestamp && numTimestamp <= maxTimestamp;
}
